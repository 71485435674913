import { isServerSide } from 'shared-code/util'

type Cached<T> = T & { cacheTimestamp: number }

const cacheVault: Record<string, Cached<any>> = {}
export async function cacher<T extends Record<string, any>>(
  key: string,
  getter: () => Promise<T>,
  delayInSeconds = Infinity
): Promise<T> {
  if (
    !Object.prototype.hasOwnProperty.call(cacheVault, key) ||
    new Date().getTime() - (cacheVault[key] as Cached<T>).cacheTimestamp > delayInSeconds * 1000
  ) {
    cacheVault[key] = { ...(await getter()), cacheTimestamp: new Date().getTime() }
  }

  return cacheVault[key] as T
}

/*
сразу выставляем ключ = pending
если встретили pending - ждем 1 сек, если не обновился то
делаем запрос
*/

type Getter<T> = () => Promise<T>
export async function browserCached<T>(key: string, getter: Getter<T>): Promise<T> {
  if (isServerSide()) {
    throw new Error("This function can't be used outside browser environment")
  }
  const pendingVal = 'pending'

  const cached = await getPendingBrowserCached(key, pendingVal, 1000)
  if (cached) {
    return JSON.parse(cached) as T
  }

  window.sessionStorage.setItem(key, pendingVal)
  const newValue = await getter()
  window.sessionStorage.setItem(key, JSON.stringify(newValue))
  return newValue
}

function getPendingBrowserCached(
  key: string,
  pendingVal: string,
  timeout: number
): Promise<string | null> {
  const retryInterval = 50
  return new Promise(r => {
    let elapsed = 0
    const handler = (): void => {
      const val = window.sessionStorage.getItem(key)

      if (val === null) {
        r(null)
        return
      }

      if (val === pendingVal) {
        elapsed += retryInterval
        if (elapsed > timeout) {
          r(null)
          return
        }

        setTimeout(handler, retryInterval)
        return
      }

      if (val) {
        r(val)
      }
    }

    setTimeout(handler, retryInterval)
  })
}

export async function sessionCache(key: string, getter: () => Promise<string>): Promise<string> {
  const current = window.sessionStorage.getItem(key)
  if (current === null) {
    const newVal = await getter()
    window.sessionStorage.setItem(key, newVal)
    return newVal
  }
  return current
}

// type Getter<T> = () => Promise<T>
// export async function browserCached<T>(key: string, getter: Getter<T>): Promise<T> {
//   if (isServerSide()) {
//     throw new Error("This function can't be used outside browser environment")
//   }

//   const cached = window.sessionStorage.getItem(key)
//   if (cached) {
//     return JSON.parse(cached) as T
//   }

//   const newValue = await getter()
//   window.sessionStorage.setItem(key, JSON.stringify(newValue))
//   return newValue
// }
